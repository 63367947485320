<template>
  <div class="banner" style="background:#fff;padding:20px;margin-bottom:0;">
    <warning-tip code="TB_SOAR_WORD"></warning-tip>
    <main-top title="淘宝搜索飙升词" desc="每个类目下今日访问飙升的关键词，结合生意参谋和直通车数据，数据为周数据。" spend="TB_SOAR_WORD" />
    <!-- 下拉选择 -->
    <div class="input-wrapper">
      <div class="input-line">
        <div class="input-in">
          <el-row style="padding:10px;width:100%;">
            <span v-loading="categoryLoading">
              <el-cascader v-model="categoryVal" :options="categoryList"
               style="width:30%;min-width:200px;"
               placeholder="请选择类目"
                size="small"></el-cascader>
            </span>
            <span style="margin-left:20px;">
              <el-button type="primary" round  @click="seekFn(categoryVal[categoryVal.length-1])"  size="small">搜索</el-button>
            </span>
          </el-row>
          <el-row style="width:100%;">
            <!-- 查询结果 -->
            <div class="search-tool">
              <div class="search-result">
                <div class="search-control">
                  <span class="search-time">统计日期：{{ statisDate }}</span>
                  <span style="float:right;">
                    <el-button type="primary" size="small" @click="downloadExcel">一键导出</el-button>
                  </span>

                </div>
              </div>
            </div>

          </el-row>
        </div>
      </div>

    </div>




    <!-- 复选框下面的table -->
    <el-table  height="500" size="mini" :data="
        tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)
      " highlight-current-row style="width: 100%" v-loading="loading" @sort-change="clickSort">
      <el-table-column v-for="(item, index) in checkbox_group" :key="index" 
      :label="item.name" 
        align="center"
        :prop="item.value"
        :sortable="index == 0 ? false : 'custom'" :min-width="item.minWidth ? item.minWidth : '60px'"
        >
        <template slot-scope="scope">
           {{scope.row[item.value]}}
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination class="pagination" 
      :current-page.sync="currentPage" :page-sizes="[5, 10, 20, 50, 100]"
      :page-size.sync="pagesize" layout="total, sizes, prev, pager, next, jumper" 
      :total="tableData.length">
    </el-pagination>


    <SyncLoading v-if="$store.state.fishFlag"></SyncLoading>

  </div>

</template>


<script>

  import {
    getAllCates,
    tbSoarWord,
  } from "@/request/api"; //这里是引入请求

  import moment from "moment";


  const checkbox_group = [{
      value: "keyword",
      name: "关键词"
    },
    {
      value: "soarRank",
      name: "飙升榜"
    },
    {
      value: "seIpvUv",
      name: "搜索人数"
    },
    {
      value: "clickHits",
      name: "点击人数"
    },
    {
      value: "clickRate",
      name: "点击率"
    },
    {
      value: "payNum",
      name: "支付人数"
    },
    {
      value: "payRate",
      name: "支付率"
    },
  ];

  export default {
    data() {
      return {
        categoryLoading: false,
        categoryVal: [],
        categoryList: [],
        checkbox_group,
        tableData:[],
        excelData:[],
        pagesize:10,
        currentPage: 1,
        loading: false,
        statisDate: "",
      };
    },
    components: {
       
    },
    created() {
      this.statisDate = moment().add(6 - moment().day() - 7, "days").format("YYYY-MM-DD");
      this.initCategoryList();
    },
    methods: {
      clickSort(rowObj) {
        this.sort(rowObj.prop, rowObj.order);
      },
      // 附有千位分割符排序方法
      sort(temp, order) {
        if (order == "descending") {
          //降序方法
          this.tableData.sort((a, b) => {
            return parseFloat(b[temp]) - parseFloat(a[temp]);
          });
        } else {
          //升序方法
          this.tableData.sort((a, b) => {
            return parseFloat(a[temp]) - parseFloat(b[temp]);
          });
        
        }
      },
      // 点击搜索的方法
      seekFn(categoryId) {
        if(!categoryId){
            this.$message.error("请选择一个类目");
            return;
        }
        this.currentPage = 1;
        //新方法,点击搜索发送请求
        this.$store.commit("alterFishFlag", true); //打开load
        this.$store.commit("alterFishLoadNumber", 100000); //传load时间
        let formObj = {
          cateId: categoryId
        }

        tbSoarWord(formObj).then((data) => {
          if(data.data == null || data.data.length == 0){
              this.$message('该类目暂无数据')
              this.tableData = []
            }else{
              data.data.forEach(function(val){
                val.clickRate = Math.round(val.clickRate * 10000) / 100  + "%";
                val.payNum = Math.round(val.payNum);
                val.payRate = Math.round(val.payRate * 10000) / 100 + "%";
              });
              this.tableData = data.data;
            }
        }).finally(() => {
          this.$store.commit("alterFishFlag", false);
        });

      },

      initCategoryList() {
        if (this.categoryList.length == 0) {
          this.categoryLoading = true;
          getAllCates().then((data) => {
            this.categoryLoading = false;
            this.categoryList = this.createOptions(data.data);
          })
        }
      },

      createOptions(data) {
        var t = [],
          e = [],
          n = [];
        data.forEach((function (i) {
          var r = {
            value: i.i,
            label: i.n,
            parent: i.p,
            children: []
          };
          0 == i.l ? t.push(r) : 1 == i.l ? e.push(r) : n.push(r)
        }));
        var map = {};
        return t.forEach((function (i) {
            return map[i.value] = i
          })),
          e.forEach((function (i) {
            return map[i.value] = i
          })),
          e.forEach((function (i) {
            return map[i.parent].children.push(i)
          })),
          n.forEach((function (i) {
            return map[i.parent].children.push(i)
          })),
          n.forEach((function (i) {
            return !i.children.length && delete i.children
          })),
          e.forEach((function (i) {
            return !i.children.length && delete i.children
          })),
          t.forEach((function (i) {
            return !i.children.length && delete i.children
          })),
          n.forEach((function (i) {
            return map[i.value] = i
          })),
          t
      },
      // 表格导出的方法
      downloadExcel() {

        this.$confirm("确定下载列表文件?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            this.excelData = this.tableData;
            this.export2Excel();
          })
          .catch(() => {});
      },
      export2Excel() {

        var that = this;
        require.ensure([], () => {
          const {
            export_json_to_excel
          } = require("@/excel/export2Excel"); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
          // 导出的表头名信息
          const tHeader = [
            "关键词",
            "飙升榜",
            "搜索人数",
            "点击人数",
            "点击率",
            "支付人数",
            "支付率",
          ];
          // 导出的表头字段名，需要导出表格字段名
          const filterVal = [
            "keyword",
            "soarRank",
            "seIpvUv",
            "clickHits",
            "clickRate",
            "payNum",
            "payRate",
          ];

          const list = that.excelData;

          const data = that.formatJson(filterVal, list);

          let curDate = moment().format("YYYY-MM-DD HH:mm:ss");
          export_json_to_excel(tHeader, data, "淘宝搜索飙升词-" + curDate); // 导出的表格名称，根据需要自己命名
        });
      },
      // 格式转换，直接复制即可
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]));
      },

    },
  };
</script>

<style lang="less" scoped>
  .input-wrapper {
    display: flex;
  }

  .input-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    flex: 1;

    .input-in {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      .el-input {
        margin-right: 20px;
      }
    }
  }

  .box-container {
    display: flex;
    align-items: center;

    span {
      margin-left: 20px;
      color: #17b3a3;
    }
  }

  .look_btn {
    margin-right: 12px;
    cursor: pointer;
    color: #17b3a3;
  }

  .pane {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .pane-in {
      width: 180px;
      /* height: 100px; */
      border: 1px solid #999;
      border-radius: 6px;
      padding: 0 20px;
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;

      .center {
        font-size: 18px;
        margin: 0;
      }

      .bottom {
        display: flex;
        justify-content: space-between;
      }

      p {
        margin: 5px 0;
      }

      .el-icon-download {
        color: #45c2b5;
        font-weight: bold;
      }

      .el-icon-upload2 {
        color: red;
        font-weight: bold;
      }
    }

    .border-color {
      border: 2px solid #45c2b5;
    }

    .e-charts {
      margin: 10px 0;
    }
  }

  .alterColor1 {
    background-color: #fff;
    color: #000;
  }

  .alterColor2 {
    background-color: #409eff;
    color: #fff;
  }

  .monthJudge2 {
    background-color: #e6a23c;
    color: #fff;
  }

  .filterBox {
    display: flex;
    line-height: 40px;
  }

  /* /deep/.el-table .caret-wrapper {
  vertical-align: baseline;
} */

  .input-condition {
    display: inline-block;
    padding-left: 20px;
  }

  .search-tool {
    padding-left: 0px;
  }

  .search-result {
    padding-left: 0px;
  }

  .search-arrow {
    position: relative;
    display: inline-block;
    border: solid 1px #409eff;
    border-right: none;
    line-height: 28px;
    font-size: 14px;
    padding: 0px 20px;
    background-color: #409eff;
    color: #fff;
  }

  .search-arrow:before {
    content: "";
    position: absolute;
    display: inline-block;
    line-height: 0;
    height: 0px;
    border: solid 15px #409eff;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    left: 100%;
    top: -1px;
    margin-left: 0px;
  }


  .search-control {
    padding: 8px;
    text-align: right;
    overflow: hidden;
  }

  .search-time {
    line-height: 34px;
    float: left;
    font-size: 14px;
  }

  .search-explain {
    padding-top: 0px;
    padding-bottom: 20px;
    color: #333;
    line-height: 1.8;
    font-size: 12px;
  }
</style>